/* EmailModal.css */
.email-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.email-modal.open {
    display: flex;
}

.email-modal-content {
    background-color: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
    position: relative;
    text-align: end;
    font-size: 40px;
    cursor: pointer;
    color: #000;
}

form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    padding: 20px;
}

label {
    margin-bottom: 5px;
}

input,
textarea {
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;

}

#button-container-01 {
    padding-top: 10px;
}

#send-button-01 {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 3px;
    cursor: pointer;
}

#send-button-01:hover {
    border: 2px solid #9C4343;
    color: #fff;
    background-color: #9C4343;
    box-shadow: 5px 5px 10px #522e2e;
}

.social-media-links {
    padding: 20px;
    width: fit-content;
    display: flex;
    margin: auto;
}

.social-media-links img {
    margin-left: 5px;
    margin-right: 5px;
    width: 50px;
    border-radius: 50%;

}

.social-media-links img:hover {
    box-shadow: 2px 2px 10px #522e2e;
}