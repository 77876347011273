
.timeline {
    position: relative;
    margin: auto;
    max-width: 1px;
    /* Set a max-width for the timeline */
    background-color: #000;
    height: 95%;
}

.event {
    position: relative;
    margin: 20px 0;
}

.event::after {
    content: '';
    position: absolute;
    background-color: #000;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
}

.event-content {
    max-width: 400px;
    margin-left: 20px;
}

#event-01 {
    top: 30%;
    left: 0;  
}

#event-02 {
    top: 50%;
    left: 0;
}

#event-03 {
    top: 70%;
    left: 0;
}

#event-04 {
    top: 80%;
    left: 0;
}