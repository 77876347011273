.previewbox-container {
    width: 100%;
}

.previewbox-block {
    width: fit-content;
    margin: auto;
    padding: 100px;
}

.scroll-box {
    width: fit-content;
    margin: auto;
    text-align: center;
}

.scroll-text,
.down-arrow {
    position: relative;
    visibility: hidden;
    opacity: 0;
    /* Initial opacity set to 0 */
    font-size: 24px;
    transition: opacity 1s ease-in-out, font-size 1s ease-in-out;
    /* Include opacity in the transition */
}

.down-arrow,
.scroll-text {
    padding: 10px;
    display: grid;
    font-size: 24px;
}

.previewbox-block:hover .reverse-scroll-text,
.previewbox-block:hover .reverse-down-arrow {
    position: relative;
    opacity: 1;
    /* Set opacity to 1 on hover */
    font-size: 36px;
    visibility: visible;
}


.previewbox-block:hover .scroll-text,
.previewbox-block:hover .down-arrow {
    position: relative;
    opacity: 1;
    /* Set opacity to 1 on hover */
    font-size: 36px;
    visibility: visible;
}

.previewbox-block-box {
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    overflow: hidden;
}

.previewbox-block-item {
    padding: 20px;
    width: fit-content;
    height: 600px;
    margin: auto;
    border: 2px solid #000;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.previewbox-image-item {
    width: 500px;
    margin: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .previewbox-block-item::-webkit-scrollbar {
        width: 12px;
        /* Set the width of the scrollbar (adjust as needed) */
    }

    .previewbox-block-item::-webkit-scrollbar-thumb {
        background-color: transparent;
        /* Hide the thumb */
    }

    .previewbox-block-item::-webkit-scrollbar-track {
        background-color: transparent;
        /* Hide the track */
    }
}

.previewbox-block-descriptor {
    padding: 20px;
    width: 600px;
    margin: auto;
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.previewbox-block-descriptor label {
    font-size: 40px;
}

.previewbox-block-descriptor p {
    width: fit-content;
    margin: auto;
    font-size: 20px;
}

@media screen and (max-width: 1440px) {

    .previewbox-block {
        padding: 5px;
    }

    .down-arrow,
    .scroll-text {
        padding: 5px;
        font-size: 14px;
    }

    .previewbox-block:hover .reverse-scroll-text,
    .previewbox-block:hover .reverse-down-arrow {

        font-size: 26px;

    }

    .previewbox-block:hover .scroll-text,
    .previewbox-block:hover .down-arrow {

        font-size: 26px;

    }

    .previewbox-block-box {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 428px) {
    .previewbox-block {
        width: fit-content;

    }

    .previewbox-block-descriptor {
        width: fit-content;
        margin: auto;
    }

    .previewbox-block-descriptor label {
        font-size: 40px;
    }
}