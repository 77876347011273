/* ScrollContainer.css */

.sc-container {
    width: 100%;
    overflow: hidden;
    /* Ensure the container hides overflow */
}

.sc-title-block {
    width: fit-content;
    margin: auto;
}

.sc-title {
    font-size: 60px;

}

.sc-section-block {
    width: fit-content;
    margin: auto;
}

.sc-scroll-block {
    width: 400px;
    padding: 20px;
    overflow: hidden;
    margin: auto;
    display: flex;
    align-items: center;
    /* Use flex display for proper alignment */
}

.sc-section-block-image {
    width: fit-content;
    padding: 20px;
    display: inline-flex;
    margin: auto;
    transition: transform 0.5s ease;
    /* Add a smooth transition effect */
}

.sc-section-block-image-item {
    width: 200px;
    height: auto;

    margin: auto;
}

#sc-image-item-02 {
    width: 320px;
    height: 90px;
    margin: auto;
}