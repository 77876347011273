body {
  min-height: 100vh;
  background-color: #f0f0f0;

}

.content {
  min-height: 100%;
}

.landing-section {
  width: 100%;
  background-color: #996868;
}

.timeline-component {
  width: 250px;
  padding: 100px;
}

.experience-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 200px;
}

.highlight-card-component {
  width: fit-content;
  margin: auto;
  margin-top: 0;
  padding: 10px;
}

@media screen and (max-width: 1440px) {
  .experience-section {
    padding: 20px;
  }

}

@media screen and (max-width: 428px) {
  .experience-section {
    display: grid;
  }
}