.content-card-reverse {
    width: 100%;
    padding: 20px;
}

.content-card-reverse-section-block {
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 5px;
}

.content-card-reverse-block {
    width: 1000px;
    margin: auto;
    display: grid;
    padding: 10px;
}

.content-card-reverse-block-title-item {
    width: fit-content;
    margin: auto;
    margin-right: 20px;
    padding: 10px;
}

.content-card-reverse-block-title-item label {
    width: fit-content;
    font-size: 80px;
    height: 100px;
    padding: 5px;

}

.content-card-reverse-block-item {
    width: fit-content;
    text-align: start;
    padding: 20px;
}

.content-card-reverse-block-item p {
    width: 850px;
    font-size: 20px;
    padding: 20px;
}

@media screen and (max-width: 428px) {
    .content-card-reverse-block {
        width: fit-content;
    }

    .content-card-reverse-block-title-item label {
        font-size: 40px;
    }

    .content-card-reverse-block-item {
        width: fit-content;
        margin: auto;
        padding: 0;
    }

    .content-card-reverse-block-item p {
        width: fit-content;
        font-size: 20px;
        margin: auto;
    }
}