.page-header {
  width: 100%;
  padding-top: 80px;
}

#spline-frame {
  width: 460px;
  height: 350px;
  margin: auto;
  border-radius: 10%;
  border: 1px solid white;
}

.header-section {
  width: fit-content;
  margin: auto;
  display: flex;
  padding: 40px;
}

.header-title-block {
  width: fit-content;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header-title-block-item {
  font-size: 60px;
  padding-top: 20px;
  color: white;
}

.header-image-block {
  width: fit-content;
  margin: auto;
  padding: 5px;
  border-radius: 10%;
  border: 5px solid black;
  box-shadow: 5px 5px 5px #522e2e;
}

.header-image-block:hover {
  box-shadow: 5px 5px 10px #522e2e;
}

.header-image-block-item {
  width: 400px;
}


/* Mobile Responsive Styles for 576px */
@media screen and (max-width: 576px) {

  .header,
  .header-1 {
    width: 100%;
    font-size: 32px;
    /* Reduce font size further for smaller screens */
    margin-top: 20px;
    /* Further reduce top margin for better spacing */
  }

  .pg-1,
  .pg-2 {
    padding: 20px;
    /* Further reduce padding for better spacing */
  }
}

/* Mobile Responsive Styles for 375px */
@media screen and (max-width: 375px) {

  .page-header {
    display: block;
  }

  .page-title {
    display: flex;
    margin-top: 150px;
    width: 100%;
    flex-wrap: wrap;
  }


  .home-image-2 {
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  .header,
  .header-1 {
    font-size: 24px;
    /* Reduce font size further for iPhone SE and similar screens */
    margin-top: 10px;
    /* Further reduce top margin for better spacing */
  }

  .pg-1,
  .pg-2 {
    padding: 15px;
    /* Further reduce padding for better spacing */
  }
}

/* Mobile Responsive Styles for 320px */
@media screen and (max-width: 320px) {

  .page-header {
    display: block;
  }

  .page-title {
    display: flex;
    margin-top: 150px;
    width: 100%;
    flex-wrap: wrap;
  }

  .home-image-2 {
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  .header,
  .header-1 {
    width: 100%;
    font-size: 20px;
    /* Further reduce font size for smaller iPhone screens */
    margin-top: 5px;
    /* Further reduce top margin for better spacing */
  }

  .pg-1,
  .pg-2 {
    padding: 10px;
    /* Further reduce padding for better spacing */
  }
}

@media screen and (max-width: 1440px) {
  #spline-frame {
    width: 450px;
    height: 400px;
  }

  .header-title-block-item {
    font-size: 40px;
  }

  .ctabutton-item {
    font-size: 10px;
  }
}

@media screen and (max-width: 428px) {
  #spline-frame {
    width: 250px;
    height: 200px;
  }

  .header-title-block-item {
    font-size: 40px;
  }

  .ctabutton-item {
    font-size: 10px;
  }

}