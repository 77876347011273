.ctabutton {
    width: fit-content;
    margin: auto;
    padding: 50px;
}

.ctabutton-block {
    width: fit-content;
    margin: auto;
}

.ctabutton-block-item {
    width: fit-content;

}

.ctabutton-item {
    width: fit-content;
    font-size: 20px;
    color: #000;
    text-decoration: none;
    width: fit-content;
    margin: auto;
    padding: 15px;
    border: 2px solid #000;
    border-radius: 40px;
    box-shadow: 5px 5px 5px #522e2e;

}

.ctabutton-item:hover {
    border: 2px solid #9C4343;
    color: #fff;
    background-color: #9C4343;
    box-shadow: 5px 5px 10px #522e2e;
}

@media screen and (max-width: 1440px){

    .ctabutton-item{
      font-size: 15px;
    }
  
  }