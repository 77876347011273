/* Navbar.css */

#navbarlogo {
  color: #000;
  width: fit-content;
  display: grid;
  justify-content: center;
  align-items: center;

}


.brand {
  width: fit-content;
  margin: auto;
  font-size: 20px;
}

#brand-name-01 {
  font-weight: 800;
}

#brand-name-02 {}

.nav-item a {
  text-align: center;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 400;
  color: #000;
}

.nav-item a.active {
  color: #9C4343;
  -webkit-text-fill-color: unset;
}

.underline {
  height: 1px;
  width: 0;
  background-color: #000;
  transition: width 0.3s;
}

.underline.show {
  transition-delay: 0.1s;
}

/* To make the underline appear when the link is hovered or active */
.nav-item a:hover~.underline,
.nav-item a.active~.underline,
.brand:hover {
  color: #9C4343;
  width: 100%;
  cursor: pointer;

}

.nav-link:hover {
  cursor: pointer;
  color: #9C4343;

}

#navbar {
  width: 100%;
  position: fixed;
  margin: auto;
  padding: 20px;
  padding-left: 250px;
  padding-right: 250px;
  justify-content: space-between;
  background-color: transparent;
  /* White background with 80% opacity */
  backdrop-filter: blur(5px);
  z-index: 999;
  border-bottom: 1px #303030;
}

#navbar ::-webkit-scrollbar #navbarNav {
  width: fit-content;
  display: flex;


}

.nav-item {
  position: relative;
  padding-right: 10px;
  /* Increase the padding to add more space */
}

@media screen and (max-width: 575px) {

  /* iPhone 11 Pro and smaller */
  #navbarlogo {
    height: 80px;
    /* Adjust height for smaller iPhone screens */
  }

  .brand {
    font-size: 20px;
    /* Reduce font size for smaller screens */
  }

  .nav-item a {
    font-size: 20px;
    /* Reduce font size for smaller screens */
  }

  .nav-item {
    padding-right: 5px;
    /* Reduce the padding for better spacing on smaller screens */
  }

}

@media screen and (max-width: 768px) {
  #navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-item {
    padding-right: 5px;
    /* Reduce the padding for better spacing on smaller screens */
  }
}

@media screen and (max-width: 575px) {
  #navbarlogo {
    height: 80px;
  }

  .brand {
    font-size: 18px;
  }

  .nav-item a {
    font-size: 18px;
  }

  .nav-item {
    padding-right: 5px;
  }
}

@media screen and (max-width: 428px) {
  #navbar {
    padding: 20px;
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: row;
  }



  #navbarList {
    display: none;
  }

  .brand {
    font-size: 15px;
  }

  .nav-item a {
    font-size: 16px;
  }

  .nav-item {
    padding-right: 5px;
  }
}